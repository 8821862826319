
<template>
	<div>
		<draggable
			v-bind="dragOptions"
			tag="div"
			class="accordion"
			:list="list"
			:value="value"
			@input="emitter"
		>
			<b-card no-body :key="key" v-for="(el, key) in value">
				<template v-if="el != null" >
					<b-card-header header-tag="header" role="tab">
							<b-button v-b-toggle="'collapse_'+el.horse_id" class="text-left btn-block btn" >
								<font-awesome-icon :icon="['far', 'sort']" class="mr-2" />
								{{ el.label }}
								<router-link :to="{ name: 'HorseFicheInfo', params: {horse_id: parseInt(el.horse_id)} }" class="ml-2 float-right">
									<font-awesome-icon :icon="['fal', 'link']" />
								</router-link>
							</b-button>
	
					</b-card-header>
					<b-collapse v-bind:id="'collapse_'+el.horse_id" accordion="my-accordion" role="tabpanel">
						<b-card-body>
							<draggable 
							tag="table"
							class="table"
							v-model="el.details"
							>
								<tr  :key="key" v-for="(el, key) in el.details">
									<td class="col-1"><font-awesome-icon :icon="['far', 'sort']" class="mr-2" /></td>
									<td class="col-5">
										{{ el.articles.articles_label }}
									</td>
									<td class="col-2 text-right" >
										{{ el.invoicedetails_ht }}
									</td>
									<td class="col-4">
										<router-link v-if="el.contract != null" :to="{ name: 'ContractFormv2', params: {contract_id: parseInt(el.contract.contract_id)} }" class="mr-2">
										#{{ el.contract.contract_num }} {{ el.contract.contract_config.horse.horse_nom }}
										</router-link>
									</td>
								</tr>
							</draggable>
						</b-card-body>
					</b-collapse>
						<!-- <nested-details  v-bind:id="'collapse_'+el.horse_id" class="accordion-collapse collapse" :list="el.details" :parent="key" />  -->
					
				</template>
			</b-card>
		</draggable>
	</div>
</template>

<script>
import draggable from "vuedraggable";
export default {
	name: "nested-details",
	methods: {
		emitter(value) {
			this.$emit("input", value);
		}		
	},
	components: {
		draggable
	},
	computed: {
		dragOptions() {
			return {
				animation: 0,
				group: "label",
				disabled: false,
				ghostClass: "ghost"
			};
		}
	},
	props: {
		value: {
			required: false,
			type: [Array, Object],
			default: null
		},
		list: {
			required: false,
			type: [Array, Object],
			default: null
		},
		parent: {
			type: [Number],
			default: null
		}
	}
};
</script>